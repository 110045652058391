<!--
    描述：订阅资源
    时间：2021-01-11
    Created by zwh
-->
<template>
    <div class="h100">
        <iframe :src="queryItem.url"
            name="iframe"
            ref="iframePage"
            width="100%"
            frameborder="0"
            scrolling="auto"
            height="100%"></iframe>
    </div>
</template>
<script>
import { mapState } from 'vuex'
//import { getPageList } from 'api/modules/sys.js'
export default {
    components: {
    },
    data() {
        return {

        }
    },
    created() {
        this.getTablePageList()
    },
    methods: {
        getTablePageList(){

        },
    },
    computed: {
        ...mapState('app', { clientSize: state => state.clientSize }),
        queryItem () {
            return JSON.parse(decodeURIComponent(this.$route.query.queryItem))
        },
    },
}
</script>
<style scoped lang="less">

</style>